function mobileNavHeight() {
  var windowHeight = jQuery(window).height();
  jQuery('body').append('<style>.nav.top.active { min-height: ' + windowHeight + 'px; } </style>');
}

jQuery(function ($) {
  $('html').removeClass('no-js').addClass('js');

  // mobile nav
  $('.nav').clone().removeClass('bottom').addClass('top').prependTo('#page-container');
  mobileNavHeight();

  // better ui for multiple maps
  (function () {
    var smallMaps = $('.title-bar > .map-button'),
        largeMaps = $('.page-title > .map-button');

    if (smallMaps.length > 1) {
      smallMaps.wrapAll('<div class="map-collection click-outside-close" />');
      smallMaps.first().parent().before('<a href="#" class="action toggle click-outside-close" data-toggle-target="next"><span class="icon icon-action icon-action-map">Show on Map</span></a>');
      smallMaps.each(function () {
        var $button = $(this);
        $button.append($button.find('span').first().text());
      });
    }

    if (largeMaps.length > 1) {
      largeMaps.wrapAll('<div class="map-collection click-outside-close" />');
      largeMaps.first().parent().before('<a href="#" class="action toggle click-outside-close" data-toggle-target="next"><span class="icon icon-action icon-action-map"></span>Show on Map</a>');
    }
  })();

  // nav bg colors
  (function () {
    var items = $('.nav.top dd'),
        itemCount = items.length,
        currentAlpha = 0.22,
        step = currentAlpha / itemCount;

    items.each(function () {
      $(this).css({ 'background-color': 'rgba(255,255,255,' + currentAlpha + ')' });
      currentAlpha = currentAlpha - step;
    });
  })();

  // toggles
  $('.toggle').each(function () {
    var toggleSource = $(this),
        toggleTarget = toggleSource.data('toggle-target');

    if (toggleTarget === "next") {
      toggleTarget = toggleSource.next();
    }

    $(toggleSource).css({ cursor: 'pointer' }).on('click', function (ev) {
      ev.preventDefault();
      toggleSource.toggleClass('active');
      $(toggleTarget).toggleClass('active');
      if (toggleSource.data('toggle-focus') === 'focus' && toggleSource.hasClass('active')) {
        $(toggleTarget).find('input:first').focus();
      }
    });
  });

  // click outside of dynamic elements to hide them
  (function () {
    var closeClass = 'click-outside-close',
        $closeEls = $('.' + closeClass);
    if (!$closeEls) {
      return;
    }

    document.body.addEventListener('click', function (ev) {
      var $target = $(ev.target);
      if (!$target.hasClass('toggle') &&
          !$target.hasClass(closeClass) &&
          $target.parents('.' + closeClass).length === 0) {
        $closeEls.removeClass('active');
      }
    });
  })();

  // search input clear buttons
  if (!$('body').hasClass('lt-ie10')) {
    $('.search-input').each(function () {

      function applyButton(el) {
        el.addClass('has-button').after('<a class="close-button" href="#"><img src="/Content/images/button-close.png" alt="Remove text" /></a>');
      }
      function removeButton(el) {
        el.removeClass('has-button').next('.close-button').remove();
      }

      // get initial state
      if ($(this).val()) {
        applyButton($(this));
      }

      // when the user types, detect if empty or not and apply x button as necessary
      $(this).keyup(function () {
        if ($(this).val() && !$(this).hasClass('has-button')) {
          applyButton($(this));
        } else if (!$(this).val() && $(this).hasClass('has-button')) {
          removeButton($(this));
        }
      });

      // make x button work
      $('form').on('click', '.close-button', function (ev) {
        ev.preventDefault();
        var searchEl = $(this).prev('.search-input');
        searchEl.val('').focus();
        removeButton(searchEl);
      });
    });
  }

  // event description
  if($(".event-detail-buttons a:not(.event-details-toggle)").length > 0 && $('.event-details-toggle').length > 0 && $('.event-details-content').length > 0) {
    (function () {
      var $content = $('.event-details-content'),
          $toggle = $('.event-details-toggle');

      $content.addClass('no-mobile');
      $content = $content.clone().addClass('cloned only-mobile').insertAfter($toggle).hide();

      $toggle.on('click', function (ev) {
        ev.preventDefault();
        $(this).toggleClass('active');
        $content.slideToggle(200);
      });

    })();
  }

  // search form helpers
  $(document).on("click", ".search-assist, .pagination .prev, .pagination .next, .pagination .cat", function (evt) {
    var el = $(this);
    var frm = $("#searchForm");
    if(el.is(".button.active, .pagination .current, .pagination .disabled")) {
      // already selected, or disabled: ignore the click
      evt.preventDefault();
      return false;
    }
    else if(0 < frm.length && undefined !== el.data("searchUpdates")) {
      $.each(el.data("searchUpdates"), function (key, value) {
        frm.find("input[name='" + key + "']").val(value);
      });
      frm.submit();
      evt.preventDefault();
      return false;
    }
    console.log("couldn't go!", frm, el);
  });

  $('<button type="button" class="button button--secondary">Deselect All</button>')
    .on("click", function (event) {
      $("input[name='SelectedTags']").prop("checked", false);
    })
    .appendTo(".filters__controls");

  // parent session selection confirmation
  $(document).on("click", ".icon-list-parent-session, .icon-list-parent-session-on", function (evt) {
    var $dialog = $(this).siblings(".confirm-tooltip");
    var $list = $(this).closest(".block-list,ol,ul");
    if ($dialog.offset().top + $dialog.outerHeight() > $list.offset().top + $list.outerHeight()) {
      $dialog.css({ top: "unset", bottom: "50%", visibility: "visible" });
    }
    else {
      $dialog.css({ visibility: "visible" });
    }

    $dialog.on("click", ".confirm-tooltip-close", function (evt) {
      $dialog.css("visibility", "hidden");
      evt.preventDefault();
      return false;
    });

    evt.preventDefault();
    return false;
  });


  // date & time formatting
  var gLocalTime = false;
  if ($('html').data('eventId')) {
    var key = 'local-time-display_' + $('html').data('eventId');
    var storedPreference = parseInt(localStorage.getItem(key), 10);
    if (isNaN(storedPreference)) {
      gLocalTime = false;
    }
    else {
      gLocalTime = Boolean(storedPreference);
    }
  }

  $('time[data-unix-timestamp-ms]').each(function () {
    this.dataset.original = this.innerHTML;
    this.style.cursor = 'pointer';
    formatTime(this, gLocalTime);
  });

  updatePage(gLocalTime);

  if (RDJS) {
    RDJS.dateTime = {
      'install': function (el) {
        $('time[data-unix-timestamp-ms]', el).each(function () {
          this.dataset.original = this.innerHTML;
          this.style.cursor = 'pointer';
          formatTime(this, gLocalTime);
        });
      }
    };
  }

  $(document).on('click', 'time[data-unix-timestamp-ms]:not(.disabled), button.time-toggle', function (evt) {
    gLocalTime = !gLocalTime;
    if ($('html').data('eventId')) {
      var key = 'local-time-display_' + $('html').data('eventId');
      localStorage.setItem(key, gLocalTime ? 1 : 0);
    }

    $('time[data-unix-timestamp-ms]').each(function () {
      formatTime(this, gLocalTime);
    });

    updatePage(gLocalTime);

    evt.preventDefault();
    return false;
  });

  function updatePage(localTime) {
    $('html')
      .toggleClass('use-time-local', localTime)
      .toggleClass('use-time-venue', !localTime);

    $('button.time-toggle')
      .toggleClass('local', localTime)
      .toggleClass('venue', !localTime);
  }

  function formatTime(el, localTime) {
    if (!localTime) {
      if (el.dataset.original) {
        el.innerHTML = el.dataset.original;
      }
      return;
    }

    var timestamp = new Date(Number(el.dataset.unixTimestampMs));
    if (!timestamp) {
      return;
    }

    var options = {};
    var hasDate = false;
    var hasTime = false;

    if (el.dataset.format) {
      el.dataset.format.split(/,\s*/).forEach(function (particle) {
        switch (particle) {
          case "day":
            hasDate = true;
            options.month = 'long';
            options.day = 'numeric';
            break;
          case "day-compact":
            hasDate = true;
            options.month = 'short';
            options.day = 'numeric';
            break;
          case "weekday":
            hasDate = true;
            options.weekday = 'long';
            break;
          case "year":
            hasDate = true;
            options.year = 'numeric';
            break;

          case "date-compact":
            hasDate = true;
            options.month = 'numeric';
            options.day = 'numeric';
            options.year = '2-digit';
            break;
            
          case "time":
            hasTime = true;
            options.hour = 'numeric';
            options.minute = '2-digit';
            break;
            
          case 'zone':
            options.timeZoneName = 'short';
            break;
        }
      });
    }

    var func = Date.prototype.toLocaleString;
    if (hasDate && !hasTime) {
      func = Date.prototype.toLocaleDateString;
    }
    else if (hasTime && !hasDate) {
      func = Date.prototype.toLocaleTimeString;
    }
    el.innerHTML = func.call(timestamp, [], options);
  }
});

// debounced resize from paul irish
// http://www.paulirish.com/2009/throttled-smartresize-jquery-event-handler/
(function ($, sr) {
  // debouncing function from John Hann
  // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
  var debounce = function (func, threshold, execAsap) {
    var timeout;

    return function debounced() {
      var obj = this, args = arguments;
      function delayed() {
        if (!execAsap)
          func.apply(obj, args);
        timeout = null;
      };

      if (timeout)
        clearTimeout(timeout);
      else if (execAsap)
        func.apply(obj, args);

      timeout = setTimeout(delayed, threshold || 100);
    };
  };
  jQuery.fn[sr] = function (fn) { return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };

})(jQuery, 'smartresize');

jQuery(window).smartresize(function () {
  mobileNavHeight();
});

/*
   * =============== TinyMCE initialization ==============================
   */

// this is the base common set of options: the minimal and advanced configs both tweak this before applying it
var tinymceConfig = {
  script_url: '/Scripts/tinymce/tinymce.min.js', // Location of TinyMCE script
  plugins: "lists,save,paste,visualchars,nonbreaking,wordcount,autosave,link,code,image",

  height: "500px",
  width: "100%",
  theme: "silver",
  menubar: false,
  statusbar: true,
  resize: false,

  schema: "html5",
  verify_html: true,
  convert_urls: false,

  inline_styles: false,
  formats: {
    // Use deprecated u element for underlines
    underline: { inline: 'u' },

    // Don't float images and tables
    alignleft: [
      { selector: 'figure,p,h1,h2,h3,h4,h5,h6,td,th,tr,div,ul,ol,li', styles: { textAlign: 'left' }, defaultBlock: 'div' },
      { selector: 'img,table', collapsed: false, attributes: { 'align': 'left' } }
    ],
    aligncenter: [
      { selector: 'figure,p,h1,h2,h3,h4,h5,h6,td,th,tr,div,ul,ol,li', styles: { textAlign: 'center' }, defaultBlock: 'div' },
      { selector: 'table', collapsed: false, attributes: { 'align': 'center' } }
    ],
    alignright: [
      { selector: 'figure,p,h1,h2,h3,h4,h5,h6,td,th,tr,div,ul,ol,li', styles: { textAlign: 'right' }, defaultBlock: 'div' },
      { selector: 'img,table', collapsed: false, attributes: { 'align': 'right' } }
    ],
    alignjustify: [
      { selector: 'figure,p,h1,h2,h3,h4,h5,h6,td,th,tr,div,ul,ol,li', styles: { textAlign: 'justify' }, defaultBlock: 'div' }
    ],
  },

  // Use numeric entity references (the iOS app, at least, doesn't know many named entities)
  entity_encoding: "numeric",

  // Don't restore content, but keep the autosave plugin for its warning message
  autosave_restore_when_empty: false,

  setup: function (ed) {
    ed.on('PostProcess', function (e) {
      // remove all HTML comments
      e.content = e.content.replace(/<(!--)(.|\n)*?(--)>/gi, '');
    });
  }
};

$('.tinymce').each(function () {
  var config = $.extend({}, tinymceConfig, {
    valid_elements: "strong,b,em,i,u,br,-p",
    toolbar: "bold italic underline | undo redo | cut copy paste " + ($(this).hasClass("allow-links") ? "| link unlink " : "") + "| code"
  }, $(this).data("tinymceConfig"));
  $(this).tinymce(config);
});